/*.item{
    height: 20px;
    width:20px;
    min-height:40rem;
    min-width:30rem;
    padding: 40px;
    
}
.containerCaro{
    max-width:fit-content;
    max-height: 100%;
    object-fit: contain;
}*/

/*
.item {
margin: 1%;
display:flex;
flex-direction:column;

}
.item img{
    width:100%;
    height:100%;
    border-radius: 2rem;
    pointer-events: none;
    object-fit: cover;

}

.inner-carousel{
    display:flex;
    background: lightblue;
}

.carousel{
    cursor: grab;
    max-width: 50%;
    margin: 0 20%;
    background: pink;

}
*/




    .item img{
        width:100%;
        height:100%;

        pointer-events: none;
        object-fit: cover;
        display: inline-block;
        
      
    
    }
    
    .inner-carousel{
        display:flex;
        width: 100%;
        height: 100%;
        display: flex;
        width: 1000px; /* Twice the width of the outer div to accommodate both images, changes according to number of images*/

        height: 100%;
        white-space: nowrap; 
    }
    
    .carousel{
        cursor: grab;
align-items: center;
justify-content: center;
      
        overflow: hidden;
        margin-bottom: 20px;
        margin-top: 10px;
        width: 250px; /* control viewport size of each card here, and change according to media query*/
        height: 300px;
        overflow-x: auto;
    
    }
  
 

    /*img{
        flex: 0 0 auto;
        width: 200px;
        height: 200px;
        border-radius: 2rem;
        pointer-events: none;
        display: inline-block;
      width: 400px;
      height: 300px;
    }*/

    .item{
        margin: 0px 15px;
        height: 100%;

    }



         /* Media query to stack the divs on smaller screens */

  @media (max-width: 2100px) {
    
    .carousel{
        width: 800px;
    }
 
  }
  
  @media (max-width: 2000px) {
    
    .carousel{
        width: 600px;
    }
 
  }


    
  @media (max-width: 1500px) {
    
    .carousel{
        width: 400px;
    }
 
  }

  @media (max-width: 900px) {
    
    .carousel{
        width: 200px;
    }
 
  }

  @media (max-width: 500px) {
    
    .carousel{
        width: 170px;
    }
 
  }


  